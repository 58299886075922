import(/* webpackMode: "eager", webpackExports: ["Nav"] */ "/vercel/path0/apps/web/src/ui/sections/Nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ViewTransitions"] */ "/vercel/path0/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-basis-grotesque-pro-black\",\"display\":\"swap\",\"src\":[{\"path\":\"../app/fonts/BasisGrotesquePro-Black.woff2\"}]}],\"variableName\":\"BasisGrotesqueProBlack\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-basis-grotesque-pro-black-italic\",\"display\":\"swap\",\"src\":[{\"path\":\"../app/fonts/BasisGrotesquePro-BlackItalic.woff2\"}]}],\"variableName\":\"BasisGrotesqueProBlackItalic\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-basis-grotesque-pro-bold\",\"display\":\"swap\",\"src\":[{\"path\":\"../app/fonts/BasisGrotesquePro-Bold.woff2\"}]}],\"variableName\":\"BasisGrotesqueProBold\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-basis-grotesque-pro-bold-italic\",\"display\":\"swap\",\"src\":[{\"path\":\"../app/fonts/BasisGrotesquePro-BoldItalic.woff2\"}]}],\"variableName\":\"BasisGrotesqueProBoldItalic\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-basis-grotesque-pro-light\",\"display\":\"swap\",\"src\":[{\"path\":\"../app/fonts/BasisGrotesquePro-Light.woff2\"}]}],\"variableName\":\"BasisGrotesqueProLight\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-basis-grotesque-pro-light-italic\",\"display\":\"swap\",\"src\":[{\"path\":\"../app/fonts/BasisGrotesquePro-LightItalic.woff2\"}]}],\"variableName\":\"BasisGrotesqueProLightItalic\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-basis-grotesque-pro-medium\",\"display\":\"swap\",\"src\":[{\"path\":\"../app/fonts/BasisGrotesquePro-Medium.woff2\"}]}],\"variableName\":\"BasisGrotesqueProMedium\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-basis-grotesque-pro-medium-italic\",\"display\":\"swap\",\"src\":[{\"path\":\"../app/fonts/BasisGrotesquePro-MediumItalic.woff2\"}]}],\"variableName\":\"BasisGrotesqueProMediumItalic\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-basis-grotesque-pro-regular\",\"display\":\"swap\",\"src\":[{\"path\":\"../app/fonts/BasisGrotesquePro-Regular.woff2\"}]}],\"variableName\":\"BasisGrotesqueProRegular\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-basis-grotesque-pro-italic\",\"display\":\"swap\",\"src\":[{\"path\":\"../app/fonts/BasisGrotesquePro-Italic.woff2\"}]}],\"variableName\":\"BasisGrotesqueProItalic\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/styles/fonts.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-basis-grotesque-pro\",\"display\":\"swap\",\"src\":[{\"path\":\"../app/fonts/BasisGrotesquePro-Black.woff2\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"../app/fonts/BasisGrotesquePro-BlackItalic.woff2\",\"weight\":\"900\",\"style\":\"italic\"},{\"path\":\"../app/fonts/BasisGrotesquePro-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../app/fonts/BasisGrotesquePro-BoldItalic.woff2\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"../app/fonts/BasisGrotesquePro-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../app/fonts/BasisGrotesquePro-LightItalic.woff2\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"../app/fonts/BasisGrotesquePro-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../app/fonts/BasisGrotesquePro-MediumItalic.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../app/fonts/BasisGrotesquePro-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../app/fonts/BasisGrotesquePro-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"}]}],\"variableName\":\"BasisGrotesquePro\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/app/global.css");
